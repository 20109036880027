.step1-alarm {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
}
.step1-alarm .form-alarm {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  flex: 1 1 auto;
}
.step1-alarm .form-alarm .actions {
  margin-top: 25px;
}
.step1-alarm .form-alarm .actions button:nth-child(2) {
  margin-left: 12px;
}
.step1-alarm .form-alarm h4 {
  margin-top: 21px;
}
.step1-alarm .form-alarm .input-form {
  position: relative;
  flex: auto;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 12px;
  margin: 1.1em 0px;
  font-size: 12px;
}
.step1-alarm .form-alarm .input-form svg {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.12);
}
.step1-alarm .form-alarm .input-form input {
  font: inherit;
  background: transparent;
  color: currentColor;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
}
.step1-alarm .form-alarm .input-form .error-input {
  position: absolute;
  bottom: -26px;
  font-size: 10px;
}
.step1-alarm .form-alarm .input-form-touched,
.step1-alarm .form-alarm .input-form-valid {
  border: 1px solid #348f6c;
}
.step1-alarm .form-alarm .input-form-touched svg,
.step1-alarm .form-alarm .input-form-valid svg,
.step1-alarm .form-alarm .input-form-touched input,
.step1-alarm .form-alarm .input-form-valid input {
  color: #348f6c;
}
.step1-alarm .form-alarm .input-form-error {
  border: 1px solid #E45D61;
}
.step1-alarm .form-alarm .input-form-error svg,
.step1-alarm .form-alarm .input-form-error input {
  color: #E45D61;
}
.step1-alarm .form-alarm .input-form-error .error-input {
  color: #E45D61;
}
.step1-alarm .description {
  padding-left: 100px;
}
.step1-alarm .description img {
  width: 300px;
}
/*# sourceMappingURL=step0.css.map */