.confirmation {
  background-color: white;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  max-height: calc(100% - 64px);
  width: 600px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
}
.confirmation .title {
  flex: 0 0 auto;
  margin: 0;
  padding: 16px 24px;
}
.confirmation .title h2 {
  font-size: 1.25rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}
.confirmation .content {
  flex: 0 0 auto;
  margin: 0;
  padding: 0px 24px 16px 24px;
}
.confirmation .content input {
  font: inherit;
  background: transparent;
  color: currentColor;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 12px 0px 12px 12px;
  border-radius: 6px;
}
.confirmation .footer {
  flex: 0 0 auto;
  display: flex;
  padding: 24px 8px 8px 0px;
  align-items: center;
  justify-content: flex-end;
}
.confirmation .footer .btn {
  margin-left: 15px;
}
/*# sourceMappingURL=step2.css.map */