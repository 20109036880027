.form-aside {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.form-aside .input-form {
  position: relative;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 12px;
  margin: 1.9em 0px;
  font-size: 12px;
}
.form-aside .input-form svg {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.12);
}
.form-aside .input-form input {
  font: inherit;
  background: transparent;
  color: currentColor;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
}
.form-aside .input-form .error-input {
  position: absolute;
  bottom: -26px;
  font-size: 10px;
}
.form-aside .input-form-touched,
.form-aside .input-form-valid {
  border: 1px solid #348f6c;
}
.form-aside .input-form-touched svg,
.form-aside .input-form-valid svg,
.form-aside .input-form-touched input,
.form-aside .input-form-valid input {
  color: #348f6c;
}
.form-aside .input-form-error {
  border: 1px solid #E45D61;
}
.form-aside .input-form-error svg,
.form-aside .input-form-error input {
  color: #E45D61;
}
.form-aside .input-form-error .error-input {
  color: #E45D61;
}
.form-aside .actions {
  display: flex;
  justify-content: space-between;
}
/*# sourceMappingURL=io.css.map */