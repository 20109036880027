.form-aside {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.form-aside .input-form {
  position: relative;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 12px;
  margin: 1.9em 0px;
  font-size: 12px;
}
.form-aside .input-form svg {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.12);
}
.form-aside .input-form input {
  font: inherit;
  background: transparent;
  color: currentColor;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
}
.form-aside .input-form .error-input {
  position: absolute;
  bottom: -26px;
  font-size: 10px;
}
.form-aside .input-form-touched,
.form-aside .input-form-valid {
  border: 1px solid #348f6c;
}
.form-aside .input-form-touched svg,
.form-aside .input-form-valid svg,
.form-aside .input-form-touched input,
.form-aside .input-form-valid input {
  color: #348f6c;
}
.form-aside .input-form-error {
  border: 1px solid #E45D61;
}
.form-aside .input-form-error svg,
.form-aside .input-form-error input {
  color: #E45D61;
}
.form-aside .input-form-error .error-input {
  color: #E45D61;
}
.form-aside .actions {
  display: flex;
  justify-content: space-between;
}
.circle-chart {
  align-self: center;
  display: flex;
  border: 4px solid #E2C992;
  width: 140px;
  height: 140px;
  border-radius: 100%;
  padding: 12px;
  margin-bottom: 25px;
  margin-top: 35px;
}
.circle-chart .chart-value {
  background-color: #3a9975;
  flex: 1;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.circle-chart .chart-value span {
  color: white;
}
.circle-chart .chart-value .value {
  font-size: 2em;
}
.circle-chart .chart-value .description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.7em;
}
.description .shape-item-detail,
.description .shape-item-detail-first {
  display: flex;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 10px;
  padding: 8px;
  height: 16px;
  width: 16px;
  align-items: center;
}
.description .shape-item-detail {
  background-color: #3a9975;
}
.description .shape-item-detail svg {
  color: rgba(255, 255, 255, 0.9);
}
.description .shape-item-detail-first {
  background-color: #E2C992;
}
.description .shape-item-detail-first svg {
  color: #ffffff;
}
.description .data {
  display: flex;
  flex-direction: column;
  margin-left: 9px;
}
.description .data .value {
  color: white;
  font-size: 0.8em;
}
.description .data .description {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.6em;
}
#users {
  flex: 1;
  padding: 15px;
  position: relative;
}
#users .header-section {
  font-size: 1.1em;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
#users .header-section .filter {
  animation: fadeTopBottom ease 2s;
  -webkit-animation: fadeTopBottom ease 2s;
  -moz-animation: fadeTopBottom ease 2s;
  -o-animation: fadeTopBottom ease 2s;
  -ms-animation: fadeTopBottom ease 2s;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 11em;
}
#users .header-section .action-right {
  animation: fadeBottomTop ease 2s;
  -webkit-animation: fadeBottomTop ease 2s;
  -moz-animation: fadeBottomTop ease 2s;
  -o-animation: fadeBottomTop ease 2s;
  -ms-animation: fadeBottomTop ease 2s;
  display: flex;
}
#users .scrollbar-container-account {
  width: calc(100% - 280px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#users .scrollbar-container-account .body {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
/*# sourceMappingURL=blackList.css.map */