#alarm-section {
  position: relative;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
}
#alarm-section .alarm {
  box-shadow: 2px 0px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 17px 12px 0px 0px;
  width: 270px;
  display: flex;
  flex-direction: column;
}
#alarm-section .alarm .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
  margin-bottom: 10px;
}
#alarm-section .alarm .header label {
  font-size: 14px;
}
#alarm-section .alarm .action-search-alarm {
  border: 1px solid #348f6c;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 25px;
}
#alarm-section .alarm .action-search-alarm svg {
  color: #348f6c;
}
#alarm-section .alarm .action-search-alarm input {
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  color: #348f6c;
  border: none;
  width: 100%;
}
#alarm-section .content {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 17px;
}
#alarm-section .content .header-section {
  font-size: 1.1em;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}
#alarm-section .content .header-section .filter {
  animation: fadeTopBottom ease 2s;
  -webkit-animation: fadeTopBottom ease 2s;
  -moz-animation: fadeTopBottom ease 2s;
  -o-animation: fadeTopBottom ease 2s;
  -ms-animation: fadeTopBottom ease 2s;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 11em;
}
#alarm-section .content .header-section .action-right {
  animation: fadeBottomTop ease 2s;
  -webkit-animation: fadeBottomTop ease 2s;
  -moz-animation: fadeBottomTop ease 2s;
  -o-animation: fadeBottomTop ease 2s;
  -ms-animation: fadeBottomTop ease 2s;
  display: flex;
}
#alarm-section .content .scrollbar-container-alarm {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
/*# sourceMappingURL=reports.css.map */