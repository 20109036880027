.loginPOUP {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99999;
  justify-content: center;
  align-items: center;
  display: flex;
  background: rgba(0, 0, 0, 0.3);
}
.container {
  margin: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: calc(100% - 189px);
  border-radius: 1.1em;
}
.container #map {
  margin: 2px;
  border-radius: 0 0 1.1em 1.1em;
  height: 0.001em;
  transition: height 500ms;
}
.container .show {
  height: calc(100% - 0.3em) !important;
  flex: 1;
}
.container .hide {
  height: 0.01em;
}
.short-container {
  width: calc(100% - 3.4em);
  margin: 6px 6px 6px 0px;
}
/*# sourceMappingURL=index.scss.map */