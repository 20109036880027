@import "../../css/animations.css";
.statNumber {
  animation: fadeInLeft ease 3s;
  -webkit-animation: fadeInLeft ease 3s;
  -moz-animation: fadeInLeft ease 3s;
  -o-animation: fadeInLeft ease 3s;
  -ms-animation: fadeInLeft ease 3s;
  position: relative;
  display: flex;
  flex-direction: row;
  min-width: 0;
  word-wrap: break-word;
  background: #fff !important;
  background-clip: border-box;
  border-radius: 10px;
  border: 0px solid #edeef7 !important;
  box-shadow: 0 5px 15px 5px rgba(80, 102, 224, 0.08);
  max-width: 25%;
  flex: 1 1 25%;
  padding: 8px;
  margin: 12px 12px 1.1rem 12px;
  height: 6rem;
  align-items: flex-end;
  justify-content: space-between;
}
.statNumber img,
.statNumber km,
.statNumber trips,
.statNumber speed {
  max-width: 80px;
  border-radius: 0px 0px 0px 10px;
}
.statNumber .details {
  display: flex;
  flex-direction: column;
}
.statNumber .details .title {
  font-size: 11px;
}
.statNumber .details .values {
  font-size: 28px;
  font-weight: 300;
  color: #5E886D;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: end;
}
/*# sourceMappingURL=filter-action.css.map */