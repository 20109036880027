.add-alarm {
  padding: 0px 16px;
}
.add-alarm .stepper-alarm {
  margin: 22px 0px 42px 0px;
}
.add-alarm .stepper-alarm svg {
  color: #348f6c;
}
.add-alarm .stepper-alarm .ant-steps-item-finish .ant-steps-item-title::after {
  background-color: #348f6c !important;
}
.add-alarm .stepper-alarm .ant-steps-item-title {
  font-weight: 400;
}
/*# sourceMappingURL=add-report.css.map */